import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Background from "../components/Background/Background";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="About | Mark Thistle | Thistle Financial"
        description="At Thistle Financial, we value the trust that our clients place in us as their mortgage broker and always look forward to working with new faces."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Background
        backgroundImages={heroImages}
        backgroundPosition="50% 80%"
        padding="pt-14 md:pt-16 pb-36 md:pb-28"
        className="mb-20 md:mb-32"
      >
        <div className="grid md:grid-cols-2 gap-y-12 md:gap-x-10 lg:gap-x-20 items-center">
          <div>
            <h1>What We’re About</h1>
            <p>
              Thistle Financial first opened its doors in Eden Prairie,
              Minnesota in 2005. We’re happy to be a part of this beautiful
              community and bring our mortgage expertise to the city and its
              neighboring Twin Cities. We value the trust that our clients place
              in us as their mortgage broker, and always look forward to working
              with new faces.
            </p>
            <ButtonSolid href="/request-rates/" text="Request Rates" />
          </div>
          <div>
            <div className="relative inline-flex">
              <div className="absolute top-4 right-6 z-10">
                <GatsbyImage
                  image={data.badge.childImageSharp.gatsbyImageData}
                />
              </div>
              <GatsbyImage
                image={data.frames.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </Background>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-y-8 md:gap-x-10 lg:gap-x-20 items-center max-w-5xl mx-auto">
            <div className="md:m-auto md:col-start-1 md:col-span-5">
              <GatsbyImage image={data.mark.childImageSharp.gatsbyImageData} />
            </div>
            <div className="md:col-end-13 md:col-span-7">
              <h2>Get To Know Our Founder</h2>
              <p className="text-sm font-semibold text-gray-800">
                NMLS# 358252
              </p>
              <p>
                Mark Thistle has worked in the financial services industry for
                more than 20 years. He’s also exclusively focused on mortgage
                financing for more than 15 years. Before founding Thistle
                Financial, he worked for Merrill Lynch and Thrivent Financial.
                Matching a client with the best solution is his goal, and he
                takes pride in always going above and beyond.
              </p>
              <p>
                Mark resides in Eden Prairie with his wife and two kids. Outside
                the office, Mark enjoys skiing, biking and spending time with
                friends and family at the lake.
              </p>
              <ButtonSolid modal="modal-contact" text="Contact Us" />
            </div>
          </div>
        </div>
      </section>

      <Background
        backgroundImages={
          data.promiseBackground.childImageSharp.gatsbyImageData
        }
        padding="pt-24 md:pt-32 pb-32 md:pb-40"
      >
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-mobile-7xl md:text-7xl text-white">
            Our Promise
          </h2>
          <p className="text-white text-lg md:text-xl mb-0">
            A lot has changed in the mortgage industry since Thistle Financial
            first opened its doors in 2005. Whether it's changes in loan
            underwriting requirements, new loan stimulus programs, or niche
            lender products, we know the market. We're committed to leveraging
            our experience to work with our clients to get a great rate and
            ensure the loan process is smooth.
          </p>
        </div>
      </Background>

      <Testimonials />
      <ValueProps />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/About.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/About.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: { eq: "5.0 About Us/1.0 Hero background desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(relativePath: { eq: "5.0 About Us/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    frames: file(relativePath: { eq: "5.0 About Us/1.1 Frames combo 2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 533)
      }
    }
    badge: file(relativePath: { eq: "home/2.0 Badge.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 135)
      }
    }
    mark: file(relativePath: { eq: "5.0 About Us/2.0 Mark.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 764)
      }
    }
    promiseBackground: file(
      relativePath: { eq: "5.0 About Us/3.0 Promise Background.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
